<template>
  <Modal :width="`60%`">
    <template #body>
      <content-card-full>
        <template #title>
          <div class="flex justify-content-between title align-items-center">
            <card-title>
              <template #title>
                <div class="flex" style="padding-top: 15px">
                  <h3 class="blue">Add A Recipient:</h3>
                </div>
              </template>
            </card-title>
            <Button class="clarity-close-modal-btn" @click="closeModal">
              <span class="material-icons-round grey">close</span>
            </Button>
          </div>
          <div style="margin-right: 5%" class="blurb">
            Use the inputs below to search for a recipient.
          </div>
        </template>
        <template #content-full>
          <ScrollPanel style="width: 100%; height: 50vh" class="pr-3">
            <div class="allocation-group">
              <div class="flex justify-content-between title align-items-center">
                <div class="filter-group">
                  <div class="half-width filter-set">
                    <label>Forename:</label>
                    <div class="p-inputgroup">
                      <InputText name="forename" v-model="forename" placeholder="Please specify..."/>
                    </div>
                  </div>
                  <div class="half-width filter-set">
                    <label>Surname:</label>
                    <div class="p-inputgroup">
                      <InputText name="surname" v-model="surname" placeholder="Please specify..."/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="filter-group align-items-center">
                <div class="full-width filter-set single">
                  <label>Email Address:</label>
                  <div class="p-inputgroup">
                    <InputText name="email" v-model="email" placeholder="Please specify..."/>
                  </div>
                </div>

                <div class="btn-group" v-if="adminMode">
                  <Button class="clarity-btn clarity-gradient-orange" @click="runSearch">
                    <span class="material-icons-round white mr-2" >search</span>
                  </Button>
                </div>
              </div>
            </div>
            <spacer-h/>
            <div>
              <card-title>
                <template #title>
                  <div class="flex" style="padding-top: 15px">
                    <h3 class="blue">Search Results</h3>
                  </div>
                </template>
              </card-title>
              <DataTable class="rd-table" v-model:expandedRows="expandedRows" :value="recipientOptions" v-model:selection="addRecipient" dataKey="uuid"
                         :filters="filters" columnResizeMode="fit">
                <Column selectionMode="single" style="width:5%" v-if="!adminMode"></Column>
                <Column header="Name" field="name" filterField="name" style="width:45%"></Column>
                <Column header="Email Address" field="email" ></Column>
                <Column header="CC" v-if="adminMode">
                    <template #body="slotProps">
                        <div>
                          <Button class="clarity-btn clarity-gradient-orange mr-2" @click="setRecipient(slotProps.data)">Set Recipient</Button>
                          <Button class="clarity-btn clarity-gradient-orange mr-2" @click="setCC(slotProps.data, false)">Add to CC</Button>
                        </div>
                    </template>
                </Column>
                <template #empty>
                  <div class="flex justify-content-center full-width">
                    No Search Results Found.
                  </div>
                </template>
                <template #expansion="slotProps">
                    <div style="width: 100%;">
                        <p><b>Partners:</b></p>
                        <div v-for="partner in slotProps.data.partners" :key="partner">
                            <div class="flex align-items-center">
                              <div style="width: 33%;">{{ partner.name }}</div>
                              <div style="width: 33%;">{{ partner.email }}</div>
                              <div style="width: 33%;">
                                <Button class="clarity-btn clarity-gradient-orange mr-2" @click="setCC(partner, slotProps.data)">Add to CC</Button>
                              </div>
                            </div>
                        </div>
                    </div>
                </template>
              </DataTable>

              <spacer-h/>

              <Dialog v-model:visible="showModal" class="confirm-dialog">
                  <div class="flex justify-content-between modal-header">
                      <h3 class="blue">Confirm add CC</h3>
                      <Button class="clarity-close-modal-btn" @click="closeConfirmModal"><span class="material-icons-round grey">close</span></Button>
                  </div>
                  <div class="message p-3">Are you sure you want to add this cc record? They do not currently have a permission link with the email recipient.</div>
                  <div class="btn-group pt-3 flex-row-reverse">
                      <Button class="clarity-btn clarity-gradient-orange" @click="confirmAddCC">Add CC</Button>
                      <Button class="clarity-btn clarity-gradient-grey" @click="closeConfirmModal">Cancel</Button>
                  </div>
              </Dialog>
            </div>
          </ScrollPanel>
          <div class="btn-group justify-content-between">
              <Button class="clarity-btn clarity-gradient-grey dark-grey" @click="closeModal">Dismiss</Button>
              <Button class="clarity-btn clarity-gradient-orange" @click="emitRecipient">Add Recipient</Button>
          </div>
        </template>
      </content-card-full>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/common/Modal";
import ContentCardFull from "@/components/common/ContentCardFull";
import CardTitle from "@/components/common/CardTitle";
import {useStore} from "vuex";
import {computed, inject, onMounted, ref, toRef} from "vue";
import SpacerH from "@/components/common/layout/SpacerH";
import {FilterMatchMode, FilterOperator} from "primevue/api";

import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';

export default {
  name: "MessageRecipient",
  props: ['recipient'],
  components: {
    SpacerH,
    Modal,
    ContentCardFull,
    CardTitle
  },
  setup(props) {
    const store = useStore()
    const bus = inject('bus')
    const forename = ref()
    const surname = ref()
    const email = ref()
    const addRecipient = ref()
    const localResults = ref([])
    const targetUUID = computed(() => store.getters.targetUUID)
    const expandedRows = ref([]);
    const showModal = ref(false);
    const storedCC = ref();
    const theRecipient = toRef(props, 'recipient');

    onMounted(() => {
      store.dispatch('getRecipientList')
    })

    const closeModal = () => {
      store.dispatch('setActiveModal')
      addRecipient.value = null
    }

    const closeConfirmModal = () => {
        showModal.value = false;
    }

    const adminMode = computed(() => {
        if(process.env.VUE_APP_TARGET_OVERRIDE === 'true') {
            if(targetUUID.value && targetUUID.value.startsWith('c_')) {
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    })

    const recipientOptions = computed(() => {
        if(adminMode.value){
            return localResults.value
        }else{
            return store.getters.additionalRecipients
        }
    })

    const filters = ref({
      'name': {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: computed(() => forename.value), matchMode: FilterMatchMode.CONTAINS
          },
          {
            value: computed(() => surname.value), matchMode: FilterMatchMode.CONTAINS
          }
        ]
      },
      'email': {value: computed(() => email.value), matchMode: FilterMatchMode.CONTAINS},
    })

    const runSearch = async () => {
        let endpoint = '/rest/api/get/clientsearch/';
        if(forename.value || surname.value || email.value) {
            if (forename.value) {
                endpoint += forename.value;
            }
            endpoint += ':';
            if (surname.value) {
                endpoint += surname.value;
            }
            endpoint += ':';
            if (email.value) {
                endpoint += email.value;
            }
        }

        const response = await api.get(`${toDrupalRequest(endpoint)}`)
        if (response) console.log('search response is ', response)
        if (response.clients) {
          //we want to load the clients into the result.
            localResults.value = response.clients.map((item) => {
                return {
                    "uuid": item.uuid,
                    "name": item.Forenames+' '+item.Surname,
                    "email": item.EmailAddress,
                    "partners": item.partners ? item.partners.map((original) => {
                        return {
                            "uuid": original.uuid,
                            "name": original.Forenames+' '+original.Surname,
                            "email": original.EmailAddress,
                        }
                    }) : []
                }
            })
            expandedRows.value = localResults.value.filter((r) => {
                return (r.uuid && r.partners && r.partners.length > 0)
            });
        }else{
            alert('failed to load response from endpoint!');
        }
    }

    const emitRecipient = () => {
      bus.emit('recipientSelected', addRecipient.value)
      closeModal()
    }

    const setRecipient = (recipient) => {
        bus.emit('recipientSelected', recipient)
        closeModal()
    }

    const setCC = (recipient, partner) => {
        if(theRecipient.value) {
            let linkFound = false;

            //check if recipient is in the partners for the current recipient
            if(recipient.partners) {
                recipient.partners.forEach((permission) => {
                    if (permission.uuid === theRecipient.value.uuid) {
                        linkFound = true;
                    }
                })
            }else if(partner){
                if(partner.uuid === theRecipient.value.uuid){
                    linkFound = true;
                }else {
                    if (partner.partners) {
                        partner.partners.forEach((permission) => {
                            if (permission.uuid === theRecipient.value.uuid) {
                                linkFound = true;
                            }
                        })
                    }
                }
            }

            if (linkFound) {
                bus.emit('ccSelected', recipient)
                closeModal()
            } else {
                showModal.value = true;
                storedCC.value = recipient;
                closeModal()
            }
        }else{
            //console.log('recipient is ', theRecipient)
            bus.emit('ccSelected', recipient)
            closeModal()
        }
    }

    const confirmAddCC = () => {
        bus.emit('ccSelected', storedCC.value)
        showModal.value = false
    }

    return {
      closeModal,
      emitRecipient,
      addRecipient,
      forename,
      surname,
      email,
      filters,
      recipientOptions,
      adminMode,
      runSearch,
      setRecipient,
      setCC,
      expandedRows,
      showModal,
      confirmAddCC,
      closeConfirmModal
    }
  }
}
</script>

<style scoped>
.modal:deep(.modal-close) {
  display: none !important;
}

.modal:deep(.content-title) {
  padding: 10px 20px 10px 30px;
}

.modal:deep(.card-title) {
  padding: unset;
  height: unset;
  align-items: unset;
}

.modal:deep(.card-title h3) {
  margin-right: 15px;
}

.modal:deep(.card-title::before) {
  height: unset;
  width: unset;
  margin-right: unset;
  background-color: unset !important;
}

:deep(.p-dialog.confirm-dialog){
    z-index: 150000 !important;
}

.blurb {
  font-size: 16px;
}

.filter-group {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.filter-set {
  display: flex;
  flex-direction: column;
}

.filter-group .filter-set:first-of-type {
  margin-left: 0px;
  margin-right: 20px;
}

.filter-group .filter-set:last-of-type {
  margin-left: 20px;
  margin-right: 0px;
}

.single {
  margin-left: 0 !important;
  padding-top: 15px;
}

.filter-group .filter-set {
  margin: 0 15px;
}

.filter-set label {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-content: center;
}

.half-width {
  width: 50%;
}

.btn-group button {
  padding: 15px 45px;
}

.btn-group {
  display: flex;
  align-self: end;
}
.dark-grey:hover {
  color: var(--clarity-dark-grey) !important;
}
</style>
