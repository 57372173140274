<template>
  <BlockUI :blocked="loadingState" class="round-blockui">
    <ProgressSpinner v-if="loadingState" class="centered"/>
    <content-card-full>
      <template #title>
        <card-title>
          <template #title>
            <h2 class="title" v-if="!reply">New Message</h2>
            <h5 class="title" v-show="reply" ref="scrollReply" id="scrollReply">Reply to Message</h5>
          </template>
          <template #content>
            <div class="flex align-items-center" v-if="!reply && getPreviousPage() !== 'provider'">
              <Button class="clarity-btn no-pad-right text-only-btn dark-grey" @click="processBack"><span
                  class="material-icons-round">chevron_left</span>Back to {{ getPreviousPage() }}
              </Button>
            </div>
            <div class="flex align-items-center" v-if="reply">
              <Button class="clarity-btn no-pad-right text-only-btn dark-grey" @click="closeForm"><span
                  class="material-icons-round">close</span>Close Form
              </Button>
            </div>
          </template>
        </card-title>
      </template>
      <template #content-full>
        <div v-if="getPreviousPage() === 'provider'" class="flex flex-column">
          <ProgressStepper :steps="stepOptions" :defaultPath="'/investments/add/provider'" />
          <div class="mt-6">
            <h3 class="dark-grey">Step 2: Contact Your Adviser</h3>
            <!-- <p class="body-text">PLACEHOLDER TEXT FOR ALTERNATIVE INVEST ADVISER CONTACT FLOW</p> -->
          </div>
        </div>
        <div class="message-details flex flex-column">
          <div class="flex">
            <div class="message-detail-item half-width">
              <label>Recipient:</label>
              <div class="flex align-items-center">
                <Dropdown v-model="formData.recipient" :options="recipientOptions" optionLabel="name"
                          placeholder="Select Recipient" class="full-width margin-top" @blur="v$.formData.recipient.$touch">
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                            {{ slotProps.value.name }} ({{ slotProps.value.email }})
                        </div>
                        <span v-else>
                            {{ slotProps.placeholder }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        {{ slotProps.option.name }} ({{ slotProps.option.email }})
                    </template>
                </Dropdown>
                <div style="margin-top: 15px; margin-left: 15px;" v-tooltip.top="'Add a recipient'">
                  <span class="material-icons-round grey" @click="openRecipientModal()">group_add</span>
                </div>
              </div>
              <div v-if="v$.formData.recipient.$error" class="form-error">Please select a recipient</div>
            </div>
            <div class="message-detail-item half-width no-pad-right">
              <label>Category:</label>
              <Dropdown v-model="formData.category" :options="categoryOptions" optionLabel="label" optionValue="label"
                        placeholder="Select Category" class="full-width margin-top" @blur="v$.formData.category.$touch"/>
              <div v-if="v$.formData.category.$error" class="form-error">Please select a category</div>
            </div>
          </div>
          <div class="message-detail-item no-pad-right" v-if="adminMode">
              <label>CC:</label>
              <div v-if="formData.cc.length > 0" class="message-detail-item">
                  <div class="flex flex-wrap">
                      <template v-for="person in formData.cc" :key="person">
                          <Chip :label="person.name+' ('+person.email+')'" removable @remove="removeCC(person)" />
                      </template>
                  </div>
              </div>
          </div>
          <div>
            <div class="message-detail-item no-pad-right">
              <label>Subject:</label>
              <InputText v-model="formData.subject" placeholder="Specify Subject.."
                         class="subject-input full-width margin-top" @blur="v$.formData.subject.$touch"/>
              <div v-if="v$.formData.subject.$error" class="form-error">Please enter a subject</div>
            </div>
          </div>
        </div>
        <div class="message-detail-item no-pad-right">
          <label>Message:</label>
          <Textarea v-model="formData.messagebody" :autoResize="true" rows="15" cols="30"
                    placeholder="Please enter you message here..." @blur="v$.formData.messagebody.$touch"/>
          <div v-if="v$.formData.messagebody.$error" class="form-error">Please enter message content before sending</div>
        </div>
        <div class="message-detail-item no-pad-right">
          <label>Attachments:</label>

          <div v-if="message && message.attachments" class="message-detail-item">
            <div class="flex flex-wrap">
              <a v-for="file in message.attachments" :href="file.presignedurl" :key="file.id" target="_blank"
                 class="sm-attachment-btn">
                <button class="p-button p-button-text">
                  <span class="material-icons-round mr-3">attachment</span>
                  {{ file.filename }}
                  <template v-if="isDraft">
                  <span class="ml-3" v-if="removeAttachmentLoadingState && removeAttachmentID == file.id">
                      <ProgressSpinner @click.stop strokeWidth="4"  animationDuration="1s" class="size" style="width: 23px; height: 23px;"/>
                  </span>
                  <span v-else class="material-icons hand-pointer grey ml-3" @click.stop.prevent="deleteAttachment(file)">delete</span>
                  </template>
                </button>
              </a>
            </div>
          </div>

          <div class="flex">
            <div v-for="(file, idx) in filesUploadToAWS" :key="idx" class="sm-attachment-btn"><span
                class="material-icons-round mr-3">attachment</span> {{ file }}
            </div>
          </div>
          <div class="flex uploader">
            <FileUpload
                ref="fileUploadInput"
                accept="image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
                :maxFileSize="20000000"
                :multiple="true"
                :customUpload="true"
                :show-upload-button="false"
                @select="fileSelected"
                @remove="fileRemoved"
                @clear="clearFiles"
                @uploader="myUploader"
                chooseLabel="Browse"
                class="clarity-gradient-blue"
            >
              <template #empty>
                <p>Drag and drop files to here to upload.</p>
              </template>
            </FileUpload>
          </div>
        </div>
        <div class="flex justify-content-between mt-6" v-if="getPreviousPage() === 'provider'">
          <Button class="clarity-btn clarity-gradient-grey" @click="$router.push('/investments/add/provider')"><span class="material-icons-round grey">chevron_left</span>Go Back</Button>
          <div class="flex">
            <submit-button @submitted="saveMessage" :button-class="'clarity-gradient-light-grey dark-grey'" button-text="Save As Draft" loading-text="Saving..." :loading="loadingState"></submit-button>
            <submit-button v-if="!lockDraft" class="ml-5" @submitted="sendMessage" button-text="Send Message" loading-text="Sending..." :loading="loadingState"></submit-button>
          </div>
        </div>
        <div class="flex justify-content-end mt-6" v-else>
          <submit-button @submitted="saveMessage" :button-class="'clarity-gradient-light-grey dark-grey'" button-text="Save As Draft" loading-text="Saving..." :loading="loadingState"></submit-button>
          <submit-button v-if="!lockDraft" class="ml-5" @submitted="sendMessage" button-text="Send Message" loading-text="Sending..." :loading="loadingState"></submit-button>
        </div>
        <div class="flex justify-content-end mr-2 mt-2" v-if="submitCount > 0">
          <div v-if="v$.$invalid" class="form-error">Please check all fields have been completed</div>
        </div>
      </template>
    </content-card-full>
    <MessageRecipient v-show="activeModal === 'messageRecipients'" :recipient="formData.recipient" />
  </BlockUI>
</template>

<script>
import dayjs from "dayjs";
import {useRouter} from "vue-router";
import {computed, inject, onBeforeUnmount, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import CardTitle from '@/components/common/CardTitle'
import ContentCardFull from '@/components/common/ContentCardFull'
import SubmitButton from "../common/layout/interactive/SubmitButton";
import ProgressStepper from "@/components/common/ProgressStepper";
import {required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import MessageRecipient from "@/components/modals/MessageRecipientModal";
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from "@sentry/vue";


export default {
  name: "Message",
  props: ['isReply', 'isDraft', 'recipient'],
  emits: ['closeReply'],
  components: {
    SubmitButton,
    CardTitle,
    ContentCardFull,
    ProgressStepper,
    MessageRecipient
  },
  setup(props, context) {
    const router = useRouter()
    const store = useStore()
    const bus = inject('bus')
    const message = computed(() => store.getters.getCurrentMessage)
    const reply = ref(props.isReply ?? false)
    const draft = ref(props.isDraft ?? false)
    const recipientOptions = ref([])
    const loadingState = computed(() => store.getters.isLoading('outboundmessage'))
    const removeAttachmentLoadingState = computed(() => store.getters.isLoading('removeAttachment'))
    const sender = computed( () => {
        if(store.getters.targetUUID){
            //console.log('sender is target')
            return store.getters.targetUUID;
        }
        //console.log('sender is clarity')
        return store.getters.clarityUUID;
    })
    let people = computed(() => store.getters.recipientOptions)
    // messageID set here - if new message/for attachments.
    const messageID = ref(uuidv4())
    const submitCount = ref(0)
    const lockDraft = ref(false)

    const globalTriggerMessage = computed(() => store.getters.hasGloballyTriggeredMessage)
    const additional = computed(() => store.getters.additionalRecipients)

    watch(additional, (val) => {
      let found = val.filter(recip => recip.name === globalTriggerMessage.value.recipient)
      if (found && found.length) {
        pushRecipient(found[0])
        formData.recipient = found[0]
      }
    })

    if (!props.isReply && !props.isDraft) {
      store.dispatch('setAdviser')
      recipientOptions.value = [...people.value]
    }
    if (!props.isReply && props.isDraft) {
      store.dispatch('setAdviser')
      // formData.recipient = message.value.recipient
      recipientOptions.value = [...people.value]
    }

    const pushRecipient = (recipientOpt) => {
      recipientOptions.value = [...people.value]

      if (!recipientOptions.value.includes(recipientOpt)) {
        recipientOptions.value = [recipientOpt, ...recipientOptions.value]
      }
    }

    if (props.recipient) {
      pushRecipient(props.recipient)
    }

    const openRecipientModal = () => {
      store.dispatch('setActiveModal', {modal: 'messageRecipients'})
    }

    const getSubjectLine = (subject) => {
      let subjectline = subject.toLowerCase()
      return subjectline.startsWith('re:') ? subject : 'Re: ' + subject
    }

    const formData = reactive({
      recipient: reply.value ? message.value.sender : draft.value ? message.value.recipient : '',
      cc: draft.value ? (message.value.cc ? message.value.cc : []) : [],
      category: reply.value || draft.value ? message.value.category : '',
      subject: reply.value ? getSubjectLine(message.value.subject) : draft.value ? message.value.subject : '',
      messagebody: draft.value ? message.value.messagebody : '',
    })

    if (globalTriggerMessage.value && globalTriggerMessage.value.subject) {
      formData.subject = globalTriggerMessage.value.subject
      formData.category = globalTriggerMessage.value.category
      if(typeof globalTriggerMessage.value.recipient === 'object' && Object.hasOwn(globalTriggerMessage.value.recipient, 'email') && Object.hasOwn(globalTriggerMessage.value.recipient, 'name') && Object.hasOwn(globalTriggerMessage.value.recipient, 'uuid')){
          pushRecipient(globalTriggerMessage.value.recipient)
          formData.recipient = globalTriggerMessage.value.recipient
      }else {
          // formData.recipient set by watcher above => needs to await recipients list update
          formData.recipient = recipientOptions.value.find(recipient => recipient.name === globalTriggerMessage.value.recipient)
      }
      formData.cc = []
      formData.messagebody = globalTriggerMessage.value.messagebody
      if(globalTriggerMessage.value.lockDraft){
          lockDraft.value = globalTriggerMessage.value.lockDraft;
      }
    }

    const getPreviousPage = () => {
      let last = null
      if(router.options.history.state && router.options.history.state.back) {
          last = router.options.history.state.back.split('?')[0]
      }
      if (last === null) {
        return 'inbox'
      }
      last = last && last.length ? last.split("/") : last

      return last[last.length - 1]
    }

    const processBack = () => {
        let last = null;
        if(router.options.history.state && router.options.history.state.back) {
            last = router.options.history.state.back.split('?')[0]
        }
        //console.log('last is ', last);
        if (last === null || last === '/adminsecuremessaging') {
            last = 'inbox'
        }
        resetForm();
        router.push(last)
    }

    const getDateTimeDisplay = (time) => {
      return dayjs(time).format('DD-MM-YYYY, HH:mm:ss')
    }

    const resetForm = () => {
      store.dispatch('resetGlobalMessageTrigger')
      formData.recipient = ''
      formData.cc = []
      formData.category = ''
      formData.subject = ''
      formData.messagebody = ''
      lockDraft.value = false;
    }

    bus.on('unsetMessageForm', () => {
      resetForm()
      draft.value = false
      reply.value = false
    })

    const scrollReply = ref(null);
    const scrollToReply = () => {
        console.log('scroll reply 2 is ', scrollReply);
        if (scrollReply.value) {
            // Access the DOM element using .value
            scrollReply.value.scrollIntoView({behavior: 'smooth'})
        }
    }

    bus.on('messageIsReply', async () => {
      reply.value = true
      draft.value = false
      scrollToReply();
    })

    bus.on('closeNewMessage', () => {
      context.emit('closeReply')
      resetForm()
      const replyValue = reply.value;
      const draftValue = draft.value;
      reply.value = false
      draft.value = false
      let last = ''
      if(router.options.history.state && router.options.history.state.back) {
          last = router.options.history.state.back.split('?')[0]
      }
      if (replyValue === true) {
          //console.log('last is ', last);
          if (last.includes('/adminsecuremessaging')) {
              router.push('/adminsecuremessaging/drafts')
          }else{
              router.push('/securemessaging/drafts')
          }
      } else if (draftValue === true) {
          //console.log('last is ', last);
          if (last.includes('/adminsecuremessaging')) {
              router.push('/adminsecuremessaging/sent')
          }else{
              router.push('/securemessaging/sent')
          }
      } else {
          if (last.includes('/adminsecuremessaging')) {
              router.push('/adminsecuremessaging/inbox')
          }else{
              router.push('/securemessaging/inbox')
          }
      }
    })

    bus.on('recipientSelected', (selectedRecipient) => {
      const index = formData.cc.map(x => x.uuid).indexOf(selectedRecipient.uuid);
      if (index > -1) {
          // this user is currently CCd. Remove them from the CC list.
          formData.cc.splice(index, 1);
      }
      pushRecipient(selectedRecipient)
      formData.recipient = selectedRecipient
    })

    bus.on('ccSelected', (selectedRecipient) => {
        if(selectedRecipient.uuid === formData.recipient.uuid){
            alert('Error - This user is already the recipient of this message.')
        }else {
            const index = formData.cc.map(x => x.uuid).indexOf(selectedRecipient.uuid);
            if (index > -1) {
                //already in the cc field. dont bother!
            } else {
                formData.cc.push(selectedRecipient)
            }
        }
    })

    const removeCC = (person) => {
        const index = formData.cc.map(x => x.uuid).indexOf(person.uuid);
        if (index > -1) { // only splice array when item is found
            formData.cc.splice(index, 1); // 2nd parameter means remove one item only
        }
    }

    const closeForm = () => {
      resetForm()
      reply.value = false
      draft.value = false
      context.emit('closeReply')
    }

    const filesToUpload = ref([])
    const filesUploadedToAWS = ref([])
    const fileUploadInput = ref(null);

    const myUploader = async (event) => {
      let count = 0
      let uploaded = [];

      for (let file of event.files) {

        let payloadMessageID = messageID.value
        if (draft.value) {
            payloadMessageID = message.value.message_id
        }

        const signedUrlPayload = {
          messageID: payloadMessageID,
          filename: file.name,
          filetype: file.type,
          filesize: `${file.size} kB`
        }

        const getSignedURL = await store.dispatch('getSignedURL', signedUrlPayload)

        if (await getSignedURL.error) {
          await store.dispatch('addNotification',{severity: 'error', summary: 'Error', detail: `Unable to upload ${file.name}`, life: 5000});
        } else {

          const awsUploadPayload = {
            endpoint: getSignedURL.signedURL,
            data: file
          }

          const awsupload = await store.dispatch('uploadFile', awsUploadPayload)

          if (awsupload.error) {
            await store.dispatch('addNotification',{
              severity: 'error',
              summary: 'Error',
              detail: `Unable to upload ${file.name} to aws`,
              life: 5000
            });
          } else {
            count++
            uploaded.push(file);
            if (!filesUploadedToAWS.value.includes(file.name)) {
              filesUploadedToAWS.value = [...filesUploadedToAWS.value, file.name]
            }
          }
        }
      }

      for (let file of uploaded) {
        // remove from event files and files to upload.
        const index = event.files.indexOf(file)
        event.files.splice(index, 1);
        const index2 = filesToUpload.value.indexOf(file)
        filesToUpload.value.splice(index2, 1);

      }

      if (count > 0) {
        await store.dispatch('addNotification',{severity: 'info', summary: 'Success', detail: `${count} File(s) Uploaded`, life: 5000});
      }

      if (filesToUpload.value.length > 0) {
        return false;
      } else {
        return true
      }
    }

    const fileSelected = (event) => {
      filesToUpload.value = event.files
    }

    const fileRemoved = (event) => {
      filesToUpload.value = event.files
    }

    const clearFiles = () => {
      filesToUpload.value = [];
    }

    const removeNullValuesFromMessagePayload = (message) => {
      return Object.keys(message).forEach(key => {
        if (message[key] === null) {
          delete message[key];
        }
      });
    }

    const getBaseMessagePayload = () => {
        const ccArray = formData.cc.map((x) => x.uuid);
        return {
        recipient: formData.recipient.uuid,
        cc: ccArray,
        sender: sender.value,
        subject: formData.subject,
        category: formData.category,
        messagebody: formData.messagebody,
        previousmessageid: reply.value === true ? message.value['message_id'] : null
      }
    }

    const saveMessage = async () => {
      submitCount.value++
      v$.value.$touch();
      if (v$.value.$invalid) return

      let sendMessage = true;

      // start loading state.
      store.dispatch('setLoading', {id: 'outboundmessage', state: true})
      if (filesToUpload.value.length > 0) {
        // we need to upload these and check if successful.
        sendMessage = await myUploader(fileUploadInput.value)
      }
      // no error ocurred uploading the files so we are good to go!
      if (sendMessage) {
        let messagePayload = {...getBaseMessagePayload(), draft: 1, send: false}
        removeNullValuesFromMessagePayload(messagePayload)
        fireAction(messagePayload)
      }else{
        store.dispatch('setLoading', {id: 'outboundmessage', state: false})
      }
    }

    const sendMessage = async () => {
      submitCount.value++
      v$.value.$touch();
      if (v$.value.$invalid) return

      let sendMessage = true;
      store.dispatch('setLoading', {id: 'outboundmessage', state: true})
      if (filesToUpload.value.length > 0) {
        // we need to upload these and check if successful.
        sendMessage = await myUploader(fileUploadInput.value)
      }
      // no error occurred uploading the files so we are good to go!
      if (sendMessage) {
        //loading to false pending message send.
        let messagePayload = getBaseMessagePayload()
        messagePayload = {...messagePayload, draft: 0, send: true}
        removeNullValuesFromMessagePayload(messagePayload)
        fireAction(messagePayload)
      }else{
        // on fail stop the loading state.
        store.dispatch('setLoading', {id: 'outboundmessage', state: false})
      }
    }

    bus.on('updateMessageError', () => {
      store.dispatch('addNotification',{
        severity: 'error',
        summary: 'Unable to update message.',
        detail: `Please try again later.`,
        life: 4000
      })
    })

    const fireAction = (payload) => {
      if (draft.value) {
        store.dispatch('updateMessage', {messageID: message.value.message_id, nav: true, ...payload})
      } else {
        store.dispatch('addMessage', {message_id: messageID.value, nav: !reply.value, ...payload})
      }
      store.dispatch('resetGlobalMessageTrigger')
    }

    const checkAllFields = computed(() => {
      let r = formData.recipient === ""
      let c = formData.category === ""
      let s = formData.subject === ""
      let m = formData.messagebody === ""

      return r || c || s || m
    })

    const rules = computed(() => {
        const localRules = {
            formData: {
                recipient: {required},
                category: {required},
                subject: {required},
                messagebody: {required}
            }
        }
        return localRules
    })

    const v$ = useVuelidate(rules, {formData: formData})

    const adminMode = computed(() => {
        if(process.env.VUE_APP_TARGET_OVERRIDE === 'true') {
            const targetUUID = store.getters.targetUUID;
            if(targetUUID && targetUUID.startsWith('c_')) {
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    })

    const removeAttachmentID = ref(false);
    const deleteAttachment = (attachment) => {
        removeAttachmentID.value = attachment.id;
        store.dispatch('setLoading', {id: 'removeAttachment', state: true})
        let theMessageID = messageID.value;
        if (draft.value) {
            theMessageID = message.value.message_id;
        }
        store.dispatch('removeAttachment', {messageID: theMessageID, attachmentID: attachment.id})
    }

    bus.on('autoLogoutPreserveMessage', () => {
        // store this for recovery!
        store.dispatch('storeMessageRecovery', { uuid: store.getters.clarityUUID, message: formData })
        Sentry.captureException('With data: '+JSON.stringify(formData), 'Backup Secure Message')
    })

    onBeforeUnmount(() => {
        bus.off('autoLogoutPreserveMessage')
        bus.off('updateMessageError')
        bus.off('ccSelected')
        bus.off('recipientSelected')
        bus.off('closeNewMessage')
        bus.off('messageIsReply')
        bus.off('unsetMessageForm')
    })

    return {
      formData,
      loadingState,
      checkAllFields,
      filesUploadToAWS: filesUploadedToAWS,
      closeForm,
      getPreviousPage,
      getDateTimeDisplay,
      myUploader,
      filesToUpload,
      fileUploadInput,
      clearFiles,
      fileSelected,
      fileRemoved,
      saveMessage,
      sendMessage,
      openRecipientModal,
      message,
      reply,
      categoryOptions: computed(() => store.getters.messageCategoryOptions),
      recipientOptions: computed(() => recipientOptions.value),
      globalTriggerMessage: computed(() => store.getters.hasGloballyTriggeredMessage),
      stepOptions: computed(() => store.getters.getSteps('investnowredirect')),
      v$,
      submitCount,
      adminMode,
      removeCC,
      deleteAttachment,
      removeAttachmentLoadingState,
      removeAttachmentID,
      lockDraft,
      resetForm,
      processBack,
      activeModal: computed(() => store.getters.currentActiveModal),
      scrollReply
    }
  }
}
</script>

<style scoped>
.message-detail-item {
  padding: 15px 30px 0 0;
  display: flex;
  flex-direction: column;
}

.half-width {
  width: 50%;
}

.margin-top {
  margin-top: 15px;
}

.no-pad-right {
  padding-right: 0 !important;
}

.message-detail-item {
  flex-direction: column;
}

.subject-input {
  height: 45px
}

.p-inputtext {
  padding-left: 20px;
}

label {
  margin: 10px 0;
  font-weight: bold;
}

.clarity-btn {
  padding: 15px 45px;
}

.text-only-btn {
  box-shadow: unset;
  border: none;
  background: none;
}

.text-only-btn:hover {
  background: none;
  color: var(--clarity-dark-grey);
}

.sender-link, .sender-link:visited {
  text-decoration: underline;
  color: var(--clarity-light-blue);
}

.dark-grey:hover {
  color: var(--clarity-dark-grey) !important;
}

.uploader {
  width: 100%;
}

.uploader > .p-fileupload {
  width: 100%;
}

.uploader:deep(.p-fileupload-files) {
  display: flex;
  flex-wrap: wrap;
}

/* upload row image */
.uploader:deep(.p-fileupload-row div:first-of-type) {
  display: none;
}

/* upload row name */
.uploader:deep(.p-fileupload-row .p-fileupload-filename) {
  /*background: yellow;*/
  color: var(--clarity-dark-grey);
  width: fit-content;
}

/* upload row size */
.uploader:deep(.p-fileupload-row div:nth-of-type(3)) {
  /*background: orange;*/
  width: fit-content;
  display: none;
}

/* upload row button */
.uploader:deep(.p-fileupload-row div:last-of-type) {
  /*background: red;*/
  width: fit-content;
  text-align: center;
}

.uploader:deep(.p-fileupload-row div:last-of-type) > button {
  background: none;
  border: 1px solid var(--clarity-light-grey);
  padding: 0;
  height: 32px;
  width: 32px;
  justify-content: center;
  color: var(--clarity-grey);
}

.uploader:deep(.p-fileupload-row div:last-of-type) > button:hover {
  color: var(--clarity-dark-grey);
  border-color: var(--clarity-dark-grey);
  background: var(--clarity-light-grey) !important;
}

.sm-attachment-btn {
  display: flex;
  align-items: center;
  height: 40px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.form-error {
    color: var(--clarity-red);
    font-weight: bold;
    margin-top: 5px;
    margin-left: 5px;
}

.break {
    flex-basis: 100%;
    height: 0;
}

#scrollReply {
    scroll-margin-top: 130px;
}

</style>
